import '@usb-shield/react-link/dist/library/styles/index.css'
import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import {
  defaultColLayoutOptions,
  defaultGridOptions,
} from '@/utils/usb-grid.util'
import { ProductData } from '@/components/ProductsArrayWrapper/productsArrayWrapper.model'
import styles from '@/components/ProductsArrayWrapper/productsArrayWrapper.module.scss'
import React, { useEffect, useState } from 'react'
import ProductCard from './displayViews/ProductCard'
import MarketingCard from './displayViews/MarketingCard'
import { Field, Form, Formik, FormikProps } from 'formik'
import USBRadioGroup from '@usb-shield/react-forms-radio-group'
import USBLink from '@usb-shield/react-link'
import USBButton from '@usb-shield/react-button'
import { USBIconFilter } from '@usb-shield/react-icons'
import { productArrayAccessCheck } from '@/utils/products/product-array-access-check'
import { useProductListQuery } from '@/modules/products/services/product-list.query'
import { useCookies } from 'react-cookie'

const fullWidthColumn = {
  ...defaultColLayoutOptions,
  offset: {
    xlarge: 2,
    large: 0,
    medium: 0,
    small: 0,
  },
  spans: {
    xlarge: 12,
    large: 16,
    medium: 8,
    small: 4,
  },
}

const twelveColsOption = {
  span: null,
  spans: {
    xlarge: 12,
    large: 12,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 2,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'start'
}
let textColumn = {
  ...twelveColsOption,
  offset: {
    xlarge: 2,
    large: 0,
    medium: 0,
    small: 0
  },
  spans: {
    xlarge: 12,
    large: 16,
    medium: 8,
    small: 4
  }
}

const nineColOptionsContent: Object = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 9,
    large: 11,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
}

const threeColsOptionContent: Object = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 3,
    large: 5,
    medium: 0,
    small: 0,
  },
  offset: {
    xlarge: 2,
    large: 0,
    medium: 0,
    small: 0,
  },
}

let filterColumn = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 8,
    large: 8,
    medium: 4,
    small: 4,
  },
  justify: 'start',
  align: 'start',
}

let clearFilterLinkColumn = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 6,
    large: 6,
    medium: 8,
    small: 4,
  },
  justify: 'center',
  align: 'center',

}

const filteredHeaderColumn = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 12,
    large: 12,
    medium: 8,
    small: 4,
  },
  justify: 'start',
  align: 'start',
}

const initialRaisedValues = {
  category: '',
  solutionAreas: '',
  productSuite: ''
}

const ProductsArrayWrapper = ({
  title,
  shortDescription,
  displayImage,
  productCardItems,
  marketingCardItems,
  filter,
}: ProductData) => {

  const { useProductVersionsListQuery } = useProductListQuery()
  const { data: productListData } = useProductVersionsListQuery()
  const [cookies] = useCookies(['locale']);
  const locale = cookies.locale || 'en-us'
  const lang = locale.substring(3)
  const checkedProducts = productArrayAccessCheck(productCardItems, productListData?.data[0], lang)

  useEffect(() => {
    if (filter) {

      const getProductList = async () => {
        if(checkedProducts.length > 0) {
          setResult(checkedProducts)
          setFilteredResult(checkedProducts)
          setSearchFilter('All Products')
          setFilterCount(checkedProducts.length)
        }
      }
      getProductList()
    }
  },[productListData]) 

  const [result, setResult] = useState([{}])
  const [filteredResult, setFilteredResult] = useState(result)
  const [searchFilter, setSearchFilter] = useState('')
  const [filterCount, setFilterCount] = useState(result.length)
  const [showFilter, setShowFilter] = useState(true)  

  const getFilterByOptions: any = () => {
    let filterListArray: any = [];
    if (productListData?.data[0]?.filters) {
      const filterCategories = productListData?.data[0]?.filters
    
      // Iterate through the object
      for (const key in filterCategories) {
        if (filterCategories.hasOwnProperty(key)) {
          filterCategories[key] = filterCategories[key].filter((item: any) => (item !== 'not applicable' && item !== 'Not Applicable'))
          let filteroptions: any;
          filteroptions = filterCategories[key].sort().map((item: any, index: any) => {
            return {
              value: item,
              label: item,
            }            
          })      
          
          if (key === 'category') {
            filterListArray[key] = [
              { value: "All Products", label: "All products"},
              ...filteroptions,      
            ]
          } else {
            filterListArray[key] = [
              ...filteroptions,      
            ]
          }
        }
      }
      return filterListArray;
    }   
  }

  const camelCaseToFlat = (c: any) => {
    c = c.replace(/[A-Z]/g, " $&");
    return c[0].toUpperCase() + c.slice(1).toLowerCase()
  }

  const onHandleClick = () => {
    if (showFilter) {
      setShowFilter(false)
    } else {
      setShowFilter(true)
    }
  }

  const getClassFromKeys = (key: any) => {
    if (key === 0 ) {
      return styles.columnOneWrapper
    } 
    return key === 1 ? styles.columnTwoWrapper : styles.columnThreeWrapper
  }

  return (
    <USBGrid
      gridGap='normal'
      alignItems='start'
      columnCount='16'
      justifyContent='stretch'
      display='grid'
      padding='normal'
      addClasses={styles.gridWrapper}
    >
      <USBColumn layoutOpts={fullWidthColumn} addClasses={styles.fullWidth}>
        {title && !filter && (
          <h2 className='headH2' data-testid='title'>
            {title}
          </h2>
        )}
        {shortDescription && (
          <div
            data-testid='short-description'
            dangerouslySetInnerHTML={{ __html: shortDescription }}
          ></div>
        )}       
      </USBColumn>
      {filter && (
        <USBColumn layoutOpts={threeColsOptionContent} addClasses={styles.fullWidth}>
       
          <Formik initialValues={initialRaisedValues} onSubmit={async (values) => {}}>
            {(props) => {
              const { handleReset }: FormikProps<any> = props
              return (
                <React.Fragment>
                  <div className={styles.filterButtonWrapper}>
                    <USBButton 
                      type="button" 
                      variant="primary" 
                      size="default" 
                      ariaLabel="Filter button"
                      id={'button-icon-filter'}
                      name={'filter'}
                      handleClick={onHandleClick}
                      iconElement={<USBIconFilter size="16" />}
                      iconPosition="left"
                      title={'Filters'}
                      dataTestId={'filterButton'}
                    >
                      <div className={styles.filterButton}>
                        <img src="/images/filter.svg" alt="Filter" />
                        <span>Filters</span>
                      </div>                
                    </USBButton>
                  </div>

                {showFilter && <section className={styles.sectionWrapper}>
                  <Form noValidate id="search_form">
                  <USBGrid
                    gridGap='normal'
                    alignItems='start'
                    columnCount='12'
                    justifyContent='stretch'
                    display='grid'
                    padding='zero'
                    addClasses={styles.TwelveColSubGridFix}
                  >
                    { productListData?.data[0]?.filters && Object.keys(productListData?.data[0]?.filters).map((value: any, key: any) =>                  
                    <USBColumn
                      layoutOpts={filterColumn}
                      addClasses={getClassFromKeys(key)}
                      key={value.toLowerCase().replace(/[-_\s.]+(.)?/g, (_: any, c: string) => c ? c.toUpperCase() : '')}
                    >
                      { key === 0 && <h3 className={styles.filterHeadingText}>Filters</h3>}
                      <div className={styles.fieldWrapper}>
                        
                        <Field
                          type="radio"
                          name="productCategory"
                          component={USBRadioGroup}
                          dataTestId={value}
                          inputName={value}
                          isOptional={true}
                          legendText={camelCaseToFlat(value)}
                          options={productListData?.data[0]?.filters && getFilterByOptions()[value] || []}
                          dynamicValue={searchFilter}
                          statusUpdateCallback={(status: any) => {
                            setSearchFilter(status.inputValue)
                            const filteredList: any = result.length > 0 && result.filter((resVal: any) => resVal?.metaData[value]?.includes(status.inputValue));
                            
                            if(filteredList.length > 0) {
                              setFilteredResult(filteredList)
                              setFilterCount(filteredList.length)
                            } else if (value === 'category' && status.inputValue === 'All Products') {
                              setFilteredResult(result)
                              setFilterCount(result.length)
                            } else {
                              setFilteredResult([{}])
                              setFilterCount(0)
                            }
                            const eventObject = {
                              target: {
                                name: value,
                                value: status.inputValue,
                                errorCode: status.errorCode,
                                rawValue: status.rawValue,
                                event: status.event,
                              },
                            }
                            props.handleChange(eventObject)
                            window.scrollTo({ left: 0, top: 200, behavior: 'smooth' })
                            return [status.inputValue]
                          }}
                        />
                      </div>
                    </USBColumn>
                    )}
                    <USBColumn
                      layoutOpts={clearFilterLinkColumn}
                    >
                    <div>
                      <USBLink
                        linkType="basic"
                        id={'test-basic-javascript-function'}
                        dataTestId={'clear-filter'}
                        addClasses={styles.clearFilterLink}
                        href="#"
                        handleClick={() => {
                          handleReset()
                          setFilteredResult(result)
                          setSearchFilter('')
                        }}
                      >
                        Clear all filters
                      </USBLink>
                    </div> 
                    </USBColumn>  
                  </USBGrid>                 
                  </Form>
                </section>}
                </React.Fragment>
              )
            }}
          </Formik>
          
        </USBColumn>
      )}     

      <USBColumn layoutOpts={filter ? nineColOptionsContent : textColumn} addClasses={styles.fullWidth}>
        <USBGrid
          gridGap='normal'
          alignItems='start'
          columnCount='12'
          justifyContent='stretch'
          display='grid'
          padding='zero'
          addClasses={styles.TwelveColSubGridFix}
        >
          {filter && (
            <USBColumn
            layoutOpts={filteredHeaderColumn}
          >
            <h3 className='headH2' data-testid='title'>
              {title}
            </h3>
            {searchFilter && <div className={styles.filterText}>Showing {filterCount} results: {searchFilter} </div>}
            </USBColumn>
          )}
          {filter && Object.keys(filteredResult).length > 0 ? 
          <ProductCard
            tiles={filter && Object.keys(filteredResult).length > 0  ? filteredResult : checkedProducts}
            displayImage={displayImage}
            levelUpHeads={title}
            filter={filter || false}
          /> 
          : 
          <ProductCard
            tiles={checkedProducts}
            displayImage={displayImage}
            levelUpHeads={title}
          />}
        </USBGrid>
      </USBColumn>
      {marketingCardItems?.length && (
        <USBColumn layoutOpts={textColumn} addClasses={styles.cardContainer}>
          <USBGrid
            gridGap={defaultGridOptions.gridGap}
            alignItems={defaultGridOptions.alignItems}
            columnCount='16'
            justifyContent={defaultGridOptions.justifyContent}
            display={defaultGridOptions.display}
            padding='zero'
            addClasses={styles.TwelveColSubGridFix + ' ' + styles.uSBCardBottom}
          >
            <MarketingCard tiles={marketingCardItems}></MarketingCard>
          </USBGrid>
        </USBColumn>
      )}
    </USBGrid>
  )
}

export default ProductsArrayWrapper
